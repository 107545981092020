<template>
  <router-view v-if="!loading"></router-view>
  <div v-else class="flex items-center justify-center h-full">
    <spinner-color-2 />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import SpinnerColor2 from "../../components/table-components/SpinnerColor2.vue";
export default {
  data() {
    return { loading: false };
  },
  components: {
    SpinnerColor2,
  },
  computed: {
    ...mapState("externalApi", ["governments"]),
    redirectPath() {
      if (this.$router.currentRoute.fullPath == "/link-with-apis")
        this.$router.push("/link-with-apis/orders");
    },
  },
  methods: {
    ...mapActions("externalApi", ["fetchGovernments"]),
  },
  async created() {
    if (!this.governments.length) {
      this.loading = true;
      await this.fetchGovernments();
      this.redirectPath;
      this.loading = false;
    } else {
      this.redirectPath;
    }
  },
};
</script>
